* {
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
}

#root {
  max-width: 140rem;
  margin: auto;
}

.search_box {
  width: 100%;
  height: 39px;
  border: 2px solid grey;
  border-radius: 3px;
  margin-top: 1px;
}

#search {
  background-image: url('../src/images/search.png');
  background-position: 2.5% 50%;
  background-size: 4.5%;
  background-repeat: no-repeat;
  z-index: 100;
  padding-top: -20px;
  padding-left: 37px;
  width: 100%;
  height: 39px;
  border: 2px solid rgb(107 114 128);
  background-color: white;
  border-radius: 3px;
  font-family: poppins;
}

.search_box1 {
  width: 100%;
  height: 39px;
  border: 2px solid grey;
  border-radius: 3px;
  margin-top: 1px;
}

#search1 {
  background-image: url('../src/images/search.png');
  background-position: 1% 50%;
  background-size: 3.5%;
  background-repeat: no-repeat;
  z-index: 100;
  padding-top: -20px;
  padding-left: 32px;
  width: 100%;
  height: 39px;
  border: 2px solid rgb(107 114 128);
  background-color: white;
  border-radius: 3px;
  font-family: poppins;
}

#search-db {
  background-image: url('../src/images/search.png');
  background-position: 1% 50%;
  background-size: 3.3%;
  background-repeat: no-repeat;
  z-index: 100;
  padding-top: -20px;
  padding-left: 37px;
  width: 100%;
  height: 39px;
  border: 2px solid rgb(107 114 128);
  background-color: white;
  border-radius: 3px;
  font-family: poppins;
}

#search-it {
  background-image: url('../src/images/location.svg');
  background-position: 4% 50%;
  background-size: 7%;
  background-repeat: no-repeat;
  z-index: 100;
  padding-top: -20px;
  padding-left: 32px;
  width: 100%;
  height: 39px;
  border: 2px solid #333333;
  background-color: white;
  border-radius: 3px;
  font-family: poppins;
}

.digit-width {
  width: 43px;
  height: 55px;
  color: black;
  border-radius: 6px;
  height: 3.2rem;
  margin-left: 2%;
  margin-right: 2%;
  font-weight: semi-bold;
  font-size: 25px;
}

.error {
  color: red;
  font-size: 8px;
  font-family: Poppins;
  font-weight: normal !important;
  height: 0px;
  float: left;
}

.errormsg {
  color: red;
  font-size: 8px;
  font-family: Poppins;
  font-weight: normal !important;
  height: 0px;
}

.border-underline {
  border-bottom: 1px solid #AAAAAA;
  width: 100%;
  margin-top: 11px;
  margin-bottom: 11px;
}

.search-p-img {
  width: 38px;
  margin-bottom: 11px;
}

.search-p-img_ {
  width: 38px;
  margin-bottom: 11px;
}

.search-t-gap_ {
  margin-top: 2px;
}

.search-t-gap {
  margin-top: 4px;
}

.enable-center {
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: left;
  margin: auto;
  width: 100%;
  border: none;
  min-height: 100vh;
}






/* Custom scroll bar starts  */
::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #2d3748;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #000;
}

::-webkit-scrollbar-thumb:active {
  background: #000000;
}

::-webkit-scrollbar-track {
  background: rgba(143, 143, 143, 0.5);
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: rgba(143, 143, 143, 0.5);
}

::-webkit-scrollbar-track:active {
  background: rgba(143, 143, 143, 0.5);
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/* Custom scrollbar ends  */


/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 28px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F8F9FD;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch .slider::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: #BDF0BC;
  box-shadow: 0 0 1px #BDF0BC;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch input:checked+.slider::before {
  background-color: #F4BCC7;
}

.switch input:focus+.slider::before {
  box-shadow: 0 0 1px #F4BCC7;
}

.switch input:checked+.slider:before {
  -webkit-transform: translateX(28px);
  -ms-transform: translateX(28px);
  transform: translateX(28px);
}

/* Rounded sliders */
.switch .slider.round {
  border-radius: 34px;
  border: 1px solid #444;
}

.switch .slider.round:before {
  border-radius: 50%;
}

/* The switch - the box around the slider */
.switch2 {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch2 input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.switch2 .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F8F9FD;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch2 .slider::before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 3px;
  bottom: 3px;
  background-color: #F4BCC7;
  box-shadow: 0 0 1px #F4BCC7;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch2 input:checked+.slider::before {
  background-color: #BDF0BC;
}

.switch2 input:focus+.slider::before {
  box-shadow: 0 0 1px #BDF0BC;
}

.switch2 input:checked+.slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

/* Rounded sliders */
.switch2 .slider.round {
  border-radius: 34px;
  border: 1px solid #444;
}

.switch2 .slider.round:before {
  border-radius: 50%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: none;
  -moz-appearance: textfield;
}

label.sc-beqWaB.jTXaFX {
  border: none !important;
  height: 100%;
}

/* label.sc-beqWaB.jTXaFX.is-disabled {

} */

.custom-shadow {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}


.kxwZmS {
  height: 94%;
  margin-top: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 50;
  border-radius: 8px;
}

.kxwZmS span {
  opacity: 0;
}

.kxwZmS+.cursor-pointer h1 {
  z-index: 30;
  color: #fff;
}
.kxwZmS+.cursor-pointer div {
  z-index: 30;
  color: #fff;
}

.mapItem path {
  outline: none !important;
}