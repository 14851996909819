.loader__wrapper {
    position: fixed;
    inset: 0;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100dvh;
    overflow: hidden;
    z-index: 10000000;
}

.loader__wrapper .loader {
    width: 300px;
    height: 200px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
}

.loader__wrapper .loader .bar {
    width: 10px;
    border-radius: 99px;
    background: #0079d7;
    animation: loader 1.5s ease-in-out infinite;
}

@keyframes loader {

    0%,
    100% {
        height: 2px;
    }

    50% {
        height: 80px;
    }
}

.loader__wrapper .loader .bar:nth-child(1) {
    animation-delay: 1s;
}

.loader__wrapper .loader .bar:nth-child(2) {
    animation-delay: 0.8s;
}

.loader__wrapper .loader .bar:nth-child(3) {
    animation-delay: 0.6s;
}

.loader__wrapper .loader .bar:nth-child(4) {
    animation-delay: 0.4s;
}

.loader__wrapper .loader .bar:nth-child(5) {
    animation-delay: 0.2s;
}

.loader__wrapper .loader .bar:nth-child(6) {
    animation-delay: 0.2s;
}

.loader__wrapper .loader .bar:nth-child(7) {
    animation-delay: 0.4s;
}

.loader__wrapper .loader .bar:nth-child(8) {
    animation-delay: 0.6s;
}

.loader__wrapper .loader .bar:nth-child(9) {
    animation-delay: 0.8s;
}

.loader__wrapper .loader .bar:nth-child(10) {
    animation-delay: 1s;
}