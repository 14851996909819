@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-gradient {
    background: linear-gradient(270deg, rgb(277, 185, 0), rgb(71, 167, 0));
}

.line-clamp-5 {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.line-clamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.sidebar_before {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.sidebar_before::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    border-radius: 4px;
    height: 100%;
    background: linear-gradient(270deg, rgb(277, 185, 0), rgb(71, 167, 0));
}